import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function ImmobilienList () {
  const data = useStaticQuery(graphql`{
  allMdx(
    filter: {fileAbsolutePath: {regex: "/immobilien(?!\\/sample)/"}}
  ) {
    edges {
      node {
        excerpt
        slug
        frontmatter {
          date
          title
          kurzbeschreibung
          m
          ort
          plz
          zimmer
          baujahr
          adresse
          homegateLink
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 608
                height: 342
                transformOptions: {cropFocus: CENTER}
                layout: CONSTRAINED
              )
            }
          }
        }
        fileAbsolutePath
      }
    }
  }
}
`)
  const posts = data.allMdx.edges
  return (
    <div>
    <div className="grid grid-cols-1 gap-4 row-gap-16 md:grid-cols-3 lg:gap-6 xl:gap-8">
    {!!posts.length && posts.map( ({ node }) => {
        const title = node.frontmatter.title || node.slug
        const excerpt = node.frontmatter.kurzbeschreibung || node.excerpt
        const image = getImage(node.frontmatter.featuredImage.childImageSharp.gatsbyImageData)
        return (
          <div key={node.slug}>
            <a href={node.frontmatter.homegateLink} className="group">
              <div className="w-full transition-all duration-300 transform group-hover:shadow-2xl group-hover:-translate-y-2">
                <p className="absolute top-0 left-0 z-10 px-2 py-1 text-xs text-white transition-all duration-300 transform bg-gray-800 group-hover:shadow-lg group-hover:-translate-y-2 group-hover:-translate-x-2 delay-50">ab {node.frontmatter.date}</p>
                {!!node.frontmatter.featuredImage && (
                  <GatsbyImage image={image} />
                )}
              </div>
            </a>
            <div className="mt-3">
              <div className="py-2">
              <a href={node.frontmatter.homegateLink}><h3 className="font-serif font-bold text-gray-900">{title}</h3></a>
                <h4 className="mb-2 text-sm">{excerpt}</h4>
                <p className="text-sm text-left text-gray-600">{node.frontmatter.adresse}, {node.frontmatter.plz}&nbsp;{node.frontmatter.ort}</p>
              </div>
              <div className="grid grid-cols-4 py-2 mb-2 text-center md:py-3">
                <div className="overflow-hidden leading-4"><span className="text-sm font-semibold">{!!node.frontmatter.zimmer ? `${node.frontmatter.zimmer}` : `-`}</span><span className="block text-xs text-gray-600">Zimmer</span></div>
                <div className="overflow-hidden leading-4"><span className="text-sm font-semibold">{!!node.frontmatter.m ? `${node.frontmatter.m}` : `-`}</span><span className="block text-xs text-gray-600">m²</span></div>
                <div className="overflow-hidden leading-4"><span className="text-sm font-semibold">{!!node.frontmatter.baujahr ? `${node.frontmatter.baujahr}` : `-`}</span><span className="block text-xs text-gray-600">Baujahr</span></div>
              </div>
              {!!node.frontmatter.homegateLink && (
                <a className="inline-block btn btn-small btn-outline" href={node.frontmatter.homegateLink}>
                  Zu Homegate
                </a>
              )}
            </div>
          </div>
        );
      })}
      </div>
      {!posts.length && (
        <p className='text-center'>Derzeit sind all unsere Objekte vermietet. Bitte schaue Sie bald wieder rein.</p>
      )}
    </div>
  );
}

export default ImmobilienList