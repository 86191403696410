import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'
// styles
import '../assets/css/contact-form.css'

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

function ContactFormImmobilienComponent({onClose}) {

  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleAttachment = e => {
    setState({ ...state, [e.target.name]: e.target.files[0] })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  const [open, setOpen] = useState(false)
  const ariaLabel = "Toggle partner input"
  const partnerDataInputWrapper = "partner-data-input"
  const isExpanded = open ? true : false
  const isHidden = open ? true : false;

  function handleAddPartnerDataClick(e) {
    e.preventDefault();
    setOpen(!open)
  }

  return (
    <form
      name="anmeldung-fuer-mietinteressenten"
      method="post"
      action="/kontakt/thank-you"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className="form form__immobilien relative p-4 md:p-12 lg:px-16"
    >
      <button 
        className="btn btn-small btn-outline ml-auto"
        onClick={onClose}
      >
        Schließen
      </button>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="anmeldung-fuer-mietinteressenten" />
      <p hidden>
        <label>
          Nicht ausfüllen:
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <h3 className="bold font-primary text-xl md:text-2xl">Anmeldung für Mietinteressenten</h3>
      <p className="mb-8 text-xs">
        Die Sicherheit Ihrer Daten ist uns wichtig. Erfahren Sie mehr dazu in unserer <Link to="/datenschutz">Datenschutzrichtlinien</Link>.
      </p>

      

      <div className="mietobjekt grid grid-cols-1 md:grid-cols-2 gap-1 mb-4">
        <label
          htmlFor="object"
        >
          Objekt:
        </label>
        <input
          className="form-input"
          id="object"
          type="text"
          onChange={handleChange}
          name="object"
          required
        />
        
        <label
          htmlFor="mietbeginn"
        >
          Mietbeginn / Bezug:
        </label>
        <input
          className="form-input"
          id="mietbeginn"
          placeholder="TT.MM.JJJJ"
          type="date"
          onChange={handleChange}
          name="mietbeginn"
          required
        />
      </div>

      <div className="flex">
        <div className="mietinteressent-data grid grid-cols-1 md:grid-cols-2 gap-1">
        <div></div>
        <p>Mietinteressent/-in</p>

        <label
          htmlFor="last-name"
        >
          Nachname
        </label>
        <input
          className="form-input"
          id="last-name"
          type="text"
          onChange={handleChange}
          name="lastName"
          required
        />

        <label
          htmlFor="first-name"
        >
          Vorname
        </label>
        <input
          className="form-input"
          id="first-name"
          type="text"
          onChange={handleChange}
          name="firstName"
          required
        />

        <label
          htmlFor="adresse-str"
        >
          Strasse
        </label>
        <input
          className="form-input"
          id="adresse-str"
          type="text"
          onChange={handleChange}
          name="adresseStr"
          required
        />

        <label
          htmlFor="adresse-number"
        >
          Hausnummer
        </label>
        <input
          className="form-input"
          id="adresse-number"
          type="text"
          onChange={handleChange}
          name="adresseStr"
          required
        />

        <label
          htmlFor="plz"
        >
          PLZ
        </label>
        <input
          className="form-input"
          id="plz"
          type="text"
          onChange={handleChange}
          name="plz"
          required
        />

        <label
          htmlFor="ort"
        >
          Ort
        </label>
        <input
          className="form-input"
          id="ort"
          type="text"
          onChange={handleChange}
          name="ort"
          required
        />

        <label
          htmlFor="tel"
        >
          Telefon
        </label>
        <input
          className="form-input"
          id="tel"
          type="tel"
          onChange={handleChange}
          name="tel"
          required
        />

        <label
          htmlFor="email"
        >
          E-Mail
        </label>
        <input
          className="form-input"
          id="email"
          type="email"
          onChange={handleChange}
          name="email"
          required
        />

        <label
          htmlFor="geburtsdatum"
        >
          Geburtsdatum
        </label>
        <input
          className="form-input"
          id="geburtsdatum"
          type="date"
          onChange={handleChange}
          name="geburtsdatum"
          required
        />

        <label
          htmlFor="zivilstand"
        >
          Zivilstand
        </label>
        <input
          className="form-input"
          id="zivilstand"
          placeholder="ledig / verheiratet"
          type="text"
          onChange={handleChange}
          name="zivilstand"
          required
        />

        <label
          htmlFor="heimatort"
        >
          Heimatort
        </label>
        <input
          className="form-input"
          id="heimatort"
          type="text"
          onChange={handleChange}
          name="heimatort"
          required
        />

        <label
          htmlFor="nationalitaet"
        >
          Nationalität
        </label>
        <input
          className="form-input"
          id="nationalitaet"
          type="text"
          onChange={handleChange}
          name="nationalitaet"
          required
        />


        <div className="col-span-2 border-t my-2"></div>
        <label
          htmlFor="beruf"
        >
          Beruf / Funktion
        </label>
        <input
          className="form-input"
          id="beruf"
          type="text"
          onChange={handleChange}
          name="beruf"
          required
        />

        <label
          htmlFor="arbeitgeber-name"
        >
          Arbeitgeber Name
        </label>
        <input
          className="form-input"
          id="arbeitgeber-name"
          type="text"
          onChange={handleChange}
          name="arbeitgeberName"
          required
        />

        <label
          htmlFor="arbeitgeber-anschrift"
        >
          Arbeitgeber Anschrift
        </label>
        <input
          className="form-input"
          id="arbeitgeber-anschrift"
          type="text"
          onChange={handleChange}
          name="arbeitgeberAnschrift"
          required
        />

        <label
          htmlFor="arbeitgeber-tel"
        >
          Arbeitgeber Telefon
        </label>
        <input
          className="form-input"
          id="arbeitgeber-tel"
          type="text"
          onChange={handleChange}
          name="arbeitgeberTel"
          required
        />

        <label
          htmlFor="arbeit-seit"
        >
          In Stellung seit
        </label>
        <input
          className="form-input"
          id="arbeit-seit"
          type="date"
          onChange={handleChange}
          name="arbeitSeit"
          required
        />

        <label
          htmlFor="jahreseinkommen"
        >
          Jahreseinkommen (brutto)
        </label>
        <input
          className="form-input"
          id="jahreseinkommen"
          type="number"
          onChange={handleChange}
          name="jahreseinkommen"
          required
        />

      </div>
        <div className="partner-data ml-6">
          <p className="mb-1">Ehepartner/-in / Partner/-in</p>
          <button
            aria-label={ariaLabel} 
            aria-expanded={isExpanded}
            aria-controls={partnerDataInputWrapper}
            open={open} 
            onClick={handleAddPartnerDataClick}
            className={(open? 'hidden' : 'display-block')}
            >
            + Partner/-in hinzufügen</button>
          <div id={partnerDataInputWrapper} open={open} aria-hidden={!isHidden} className={(open? 'partner-data-input--open' : 'partner-data-input--closed')}>
            <div className="flex flex-col">
            
              <label
                htmlFor="last-name-partner"
              >
                Nachname
              </label>
              <input
                className="form-input"
                id="last-name-partner"
                type="text"
                onChange={handleChange}
                name="lastNamePartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="first-name-partner"
              >
                Vorname
              </label>
              <input
                className="form-input"
                id="first-name-partner"
                type="text"
                onChange={handleChange}
                name="firstNamePartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="adresse-str-partner"
              >
                Strasse
              </label>
              <input
                className="form-input"
                id="adresse-str-partner"
                type="text"
                onChange={handleChange}
                name="adresseStrPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="adresse-number-partner"
              >
                Hausnummer
              </label>
              <input
                className="form-input"
                id="adresse-number-partner"
                type="text"
                onChange={handleChange}
                name="adresseStrPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="plz-partner"
              >
                PLZ
              </label>
              <input
                className="form-input"
                id="plz-partner"
                type="text"
                onChange={handleChange}
                name="plzPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="ort-partner"
              >
                Ort
              </label>
              <input
                className="form-input"
                id="ort-partner"
                type="text"
                onChange={handleChange}
                name="ortPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="tel-partner"
              >
                Telefon
              </label>
              <input
                className="form-input"
                id="tel-partner"
                type="tel"
                onChange={handleChange}
                name="telPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="email-partner"
              >
                E-Mail
              </label>
              <input
                className="form-input"
                id="email-partner"
                type="email"
                onChange={handleChange}
                name="emailPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="geburtsdatum-partner"
              >
                Geburtsdatum
              </label>
              <input
                className="form-input"
                id="geburtsdatum-partner"
                type="date"
                onChange={handleChange}
                name="geburtsdatumPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="zivilstand-partner"
              >
                Zivilstand
              </label>
              <input
                className="form-input"
                id="zivilstand-partner"
                placeholder="ledig / verheiratet"
                type="text"
                onChange={handleChange}
                name="zivilstandPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="heimatort-partner"
              >
                Heimatort
              </label>
              <input
                className="form-input"
                id="heimatort-partner"
                type="text"
                onChange={handleChange}
                name="heimatortPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="nationalitaet-partner"
              >
                Nationalität
              </label>
              <input
                className="form-input"
                id="nationalitaet-partner"
                type="text"
                onChange={handleChange}
                name="nationalitaetPartner"
                required={(open? true : false)}
              />


              <div className="col-span-2 border-t my-3"></div>
              <label
                htmlFor="beruf-partner"
              >
                Beruf / Funktion
              </label>
              <input
                className="form-input"
                id="beruf-partner"
                type="text"
                onChange={handleChange}
                name="berufPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="arbeitgeber-name-partner"
              >
                Arbeitgeber Name
              </label>
              <input
                className="form-input"
                id="arbeitgeber-name-partner"
                type="text"
                onChange={handleChange}
                name="arbeitgeberNamePartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="arbeitgeber-anschrift-partner"
              >
                Arbeitgeber Anschrift
              </label>
              <input
                className="form-input"
                id="arbeitgeber-anschrift-partner"
                type="text"
                onChange={handleChange}
                name="arbeitgeberAnschriftPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="arbeitgeber-tel-partner"
              >
                Arbeitgeber Telefon
              </label>
              <input
                className="form-input"
                id="arbeitgeber-tel-partner"
                type="text"
                onChange={handleChange}
                name="arbeitgeberTelPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="arbeit-seit-partner"
              >
                In Stellung seit
              </label>
              <input
                className="form-input"
                id="arbeit-seit-partner"
                type="date"
                onChange={handleChange}
                name="arbeitSeitPartner"
                required={(open? true : false)}
              />

              <label
                htmlFor="jahreseinkommen-partner"
              >
                Jahreseinkommen (brutto)
              </label>
              <input
                className="form-input"
                id="jahreseinkommen-partner"
                type="number"
                onChange={handleChange}
                name="jahreseinkommenPartner"
                required={(open? true : false)}
              />
            </div>

          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-3 border-t mt-2 pt-2 md:mt-4 md:pt-4">
        <fieldset>
          <legend>Bestehen Abzahlungsverträge?</legend>
          <p>
            <label>
              <input type="radio" name="abzahlungsvertraege" value="Abzahlungsverträge ja" /> Ja
            </label>
          </p>
          <p>
            <label>
              <input type="radio" name="abzahlungsvertraege" value="Abzahlungsverträge nein" /> Nein
            </label>
          </p>
        </fieldset>
        <fieldset>
          <legend>Bestehen Betreibungen?</legend>
          <p>
            <label>
              <input type="radio" name="betreibungen" value="Betreibungen ja" /> Ja
            </label>
          </p>
          <p>
            <label>
              <input type="radio" name="betreibungen" value="Betreibungen nein" /> Nein
            </label>
          </p>
        </fieldset>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-3 ">
          <label
            htmlFor="abzahlungsvertraege-gesambetrag"
          >
            Abzahlungsverträge Gesambetrag
          </label>
          <input
            className="form-input"
            id="abzahlungsvertraege-gesambetrag"
            type="number"
            onChange={handleChange}
            name="abzahlungsvertraegeGesambetrag"
            required
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-3 ">
          <label
            htmlFor="betreibungen-gesambetrag"
          >
            Betreibungen Gesambetrag
          </label>
          <input
            className="form-input"
            id="betreibungen-gesambetrag"
            type="number"
            onChange={handleChange}
            name="betreibungenGesambetrag"
            required
          />
        </div>
      
      </div>


      <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-3 border-t mt-2 pt-2 md:mt-4 md:pt-4">
        <label
          htmlFor="jetziger-vermieten"
        >
          Jetziger Vermieter beider Interessenten:
        </label>
        <textarea
          className="form-textarea"
          id="jetziger-vermieten"
          placeholder="Bitte mit Telefonnummer angeben"
          rows="2"
          onChange={handleChange}
          name="jetzigerVermieten"
          required
        />

        <label
          htmlFor="wohnadresse"
        >
          Wohnadresse der letzten drei Jahre:
        </label>
        <textarea
          className="form-textarea"
          id="wohnadresse"
          rows="2"
          onChange={handleChange}
          name="wohnadresse"
          required
        />

        <label
          htmlFor="wohnungssuche-grund"
        >
          Grund der Wohnungssuche:
        </label>
        <textarea
          className="form-textarea"
          id="wohnungssuche-grund"
          rows="2"
          onChange={handleChange}
          name="wohnungssucheGrund"
          required
        />
      </div>


      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1 md:gap-3 border-t mt-2 pt-2 md:mt-4 md:pt-4">
        <label
          htmlFor="fahrzeug-art"
        >
          Fahrzeug Art:
        </label>
        <input
          className="form-input"
          id="fahrzeug-art"
          rows="2"
          onChange={handleChange}
          name="fahrzeugArt"
          required
        />

        <label
          htmlFor="kennzeichen"
        >
          Kennzeichen:
        </label>
        <input
          className="form-input"
          id="kennzeichen"
          rows="2"
          onChange={handleChange}
          name="kennzeichen"
          required
        />

        <label
          htmlFor="musikinstrument"
        >
          Musikinstrument:
        </label>
        <textarea
          className="form-textarea"
          id="musikinstrument"
          rows="1"
          onChange={handleChange}
          name="musikinstrument"
          required
        />

        <label
          htmlFor="haustiere"
        >
          Haustiere:
        </label>
        <textarea
          className="form-textarea"
          id="haustiere"
          rows="1"
          onChange={handleChange}
          name="haustiere"
          required
        />
      </div>


      <div className=" mb-6">
        <span className="">Datei:</span>
        <div className="mt-2"></div>
        <label>
          <input type="file" name="attachment" onChange={handleAttachment} />
        </label>
      </div>

      <div className="border border-gray-300 py-1 px-3 mb-4 text-sm">
        <p>Die Hossmann Immobilien AG wird ermächtigt, über vorgenannte Personen Auskünfte einzuholen. Der/Die Unterzeichnete(n) bestätigt/bestätigen, alle Fragen wahrheitsgetreu beantwortet zu haben. Zieht der Mietinteressent nach Ausfertigung eines vereinbarten Mietvertrages seine Bewerbung zurück, hat er der Hossmann Immobilien AG eine Umtriebsentschädigung von CHF 100.- zu bezahlen. Die Anmeldung und ein allfälliger Mietvertrag ist von beiden Partnern/Intressenten zu unterzeichnen. Zur Beachtung: Haustiere sind nicht in allen unseren Liegenschaften erlaubt. Bitte informieren Sie sich vorher.</p>
      </div>

      <div className="border border-gray-300 py-1 px-3 text-sm">
        <p>Der Anmeldung sind auf jeden Fall folgende Unterlagen beizulegen:</p>
        <table className="my-2">
          <tbody>
            <tr>
              <td className="pr-3">Alle Interessenten:</td>
              <td><ul className="list-disc ml-5"><li>Kopie aktueller Betreibungsauszug der letzten drei Jahre</li></ul></td>
            </tr>
            <tr>
              <td className="pr-3">Alle Interessenten:</td>
              <td><ul className="list-disc ml-5"><li>Kopie Personalausweis oder entsprechender Aufenthaltsbewilligung</li></ul></td>
            </tr>
            <tr>
              <td className="pr-3">Alle Interessenten:</td>
              <td><ul className="list-disc ml-5"><li>Kopie Arbeitsvertrag</li></ul></td>
            </tr>
            <tr>
              <td className="pr-3">Firmen/Selbständig Erwerbende:</td>
              <td><ul className="list-disc ml-5"><li>zusätzlich Handelsregisterauszug</li></ul></td>
            </tr>
          </tbody>
        </table>
        <p>Unvollständig ausgefüllte Anmeldungen werden nicht bearbeitet!</p>
      </div>

      <div className="flex mt-6">
        <label className="flex items-center">
          <input type="checkbox" className="form-checkbox" required />
          <span className="ml-2">
            Ich bin mit den{" "}
            <Link to="/datenschutz" title="Datenschutz" className="underline">
              Datenschutzrichtlinien
            </Link>{" "}
            einverstanden
          </span>
        </label>
      </div>

      <button className="btn btn-small btn-secondary mt-4 mr-4">
        Abschicken
      </button>
    </form>
  )
}

export default ContactFormImmobilienComponent