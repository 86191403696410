import React, { useRef, useState, useLayoutEffect } from "react"
// components
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import ContactFormImmobilien from "../../../components/ContactFormImmobilien"
import ImmobilienList from "../../../components/ImmobilienListMieten"
// hooks
import useOnClickOutside from "../../../hooks/useOnClickOutside"

const MietenPage = () => {
  const contactModal = useRef()
  // State for our modal
  const [isModalOpen, setModalOpen] = useState(false)
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(contactModal, () => setModalOpen(false))

  useLayoutEffect(() => {
    if (isModalOpen) {
      // Get original value of body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden"
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle)
    }
  }, [isModalOpen])

  return (
    <Layout logoImmobilienSelected={true}>
      <Seo title="Gips. Stuck. Trockenbau. Unsere Leistungen." />

      <div
        ref={contactModal}
        className={
          isModalOpen
            ? "fixed flex justify-center w-full h-full z-50 px-4 opacity-100 transition-all duration-1000 transform translate-y-0"
            : "fixed flex justify-center w-full h-full z-50 px-4 opacity-70 transition-all duration-1000 transform -translate-y-full"
        }
      >
        <div className="self-center w-1/2 max-w-2xl max-h-screen overflow-auto bg-white shadow-md lg:w-full lg:max-w-3xl">
          <ContactFormImmobilien onClose={() => setModalOpen(false)} />
        </div>
      </div>

      <section className="container px-4 pt-40 pb-12 mx-auto lg:px-24 xl:px-32">
        <div>
          <h2 className="pb-2 text-secondary">Vielfalt in Graubünden und Zürich</h2>
          <h1 className="pb-12 h1">
            <span className="text-gray-800">
              Freistehende Mietobjekte der Hossmann Immobilien AG.
              <br />
              Ist auch Ihr neues Zuhause dabei?
            </span>
            .
          </h1>
        </div>
        <div className="max-w-md ml-auto mr-0 article">
          <p>
            Wir ver­wal­tet di­ver­se Stu­di­os, Woh­nun­gen, La­ger­hal­len,
            Tief­ga­ra­gen­plä­tze und Bü­ros zur Ver­mie­tung. Top renovierte
            Objekte in wunderschöner Lage sind unsere Spezialität.
          </p>
          <p>
            Haben Sie Fragen zu den Objekten?
            <br />
            Rufen Sie einfach an unter{" "}
            <a href="tel:+41816500030">+41 (0)81 650 00 30</a>.
          </p>
          <button
            className="mr-auto btn btn-secondary"
            onClick={() => setModalOpen(true)}
          >
            Anmeldung für Mietinteressenten
          </button>
        </div>
      </section>

      <section className="container px-4 py-4 mx-auto md:py-12 lg:py-24 lg:px-24 xl:px-32">
        {/* <ImmobilienList /> */}
      </section>
    </Layout>
  )
}

export default MietenPage
